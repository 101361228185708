<template>
  <div id="communication-list">
    <!-- list filters -->
    <v-card>
      <v-card-title>
        {{ $t("Communication") }}
        <v-chip
          outlined
          class="ml-2"
        >
          {{ totalItems }}
        </v-chip>
        <v-spacer/>
        <export-excel
          :options="downloadObject"
          @refetch-data="() => fetchItems(true, 'communication')"
        />
      </v-card-title>
      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        class="fixed-table"
        :headers="filters"
        :items="items"
        :options.sync="options"
        :page.sync="page"
        :server-items-length="totalItems"
        :loading="loading"
        :loading-text="$t('Chargement des éléments')"
        :no-data-text="$t('Pas de données')"
        :no-results-text="$t('Aucun résultat')"
        :mobile-breakpoint="250"
        hide-default-footer
        :show-select="false"
        dense
        fixed-header
        :height="tableHeight"
        @page-count="pageCount = $event"
      >
        <template #body.prepend="{ items, headers }">
          <header-filters :headers="filters"/>
        </template>
        <template #item="options">
          <Row
            :options="options"
            :config="{ url: '/communications/action/', author: 'publication.author', manuscript: 'publication.manuscript', resource: 'Communication' }"
            @refetch-data="fetchItems"
            @saved="saved"
          >
            <template #[`item.completion_date`]="{item}">
              <span v-if="item.completion_date && !item.completion_date.includes('0000')">{{
                  formatDateShort(item.completion_date || null, {
                    month: 'numeric',
                    day: 'numeric',
                    year: 'numeric'
                  })
                }}</span>
            </template>
            <template #[`item.actions`]="{item}">
              <v-menu
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>

                  <v-list-item
                    v-if="$can('edit','Author')"
                    :to="{name:'author-info',params:{id:item.publication.author_id}}"
                  >
                    <v-list-item-title>
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiFileDocumentEditOutline }}
                      </v-icon>
                      <span>{{ $t('Ajouter l\'info de l\'auteur') }}</span>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    :to="{name:'author-details',params:{id:item.publication.author_id}}"
                  >
                    <v-list-item-title>
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiChartBoxOutline }}
                      </v-icon>
                      <span>{{ $t('Fiche Synoptique') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <!-- name -->
            <template #[`item.publication.author.full_name`]="{item,index}">
              <author-widget :object="item.publication.author" />
<!--              <div
                v-if="item.publication.author && item.publication.author.id"
                class="d-flex align-center"
              >
                <v-avatar
                  :color="item.publication.author.avatar ? '' : 'primary'"
                  :class="item.publication.author.avatar ? '' : 'v-avatar-light-bg primary&#45;&#45;text'"
                  size="30"
                >
                  <v-img
                    v-if="item.publication.author.avatar"
                    :lazy-src="require(`@/assets/images/avatars/2.png`)"
                    :src="item.publication.author.avatar || require(`@/assets/images/avatars/2.png`)"
                  ></v-img>
                  <span
                    v-else
                    class="font-weight-medium"
                  >{{ avatarText(item.publication.author.full_name) }}</span>
                </v-avatar>

                <div class="d-flex flex-column ms-3">
                  <router-link
                    :to="{ name : 'author-view', params : { id : item.publication.author_id } }"
                    class="text&#45;&#45;primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                  >
                    {{ item.publication.author.full_name }}
                  </router-link>
                  &lt;!&ndash;              <small>@{{ item.publicationname }}</small>&ndash;&gt;
                </div>
              </div>-->
            </template>
            <template #[`item.publication.author.old_author`]="{item}">
              <v-icon
                v-if="item.publication.author && item.publication.author.old_author"
                small
                color="success"
              >
                {{ icons.mdiCheckCircle }}
              </v-icon>
            </template>
            <template #[`item.is_reading`]="{item}">
              <span>
                <v-icon
                  v-if="item.is_reading"
                  small
                  color="success"
                >
                  {{ icons.mdiCheckCircle }}
                </v-icon>
              </span>
            </template>
            <template #[`item.publication.facebook`]="{item}">
                <span
                  v-if="item.publication.facebook"
                >
                  Oui
                </span>
              <span
                v-else-if="item.publication.facebook === 0"
              >
                  Non
                </span>
              <span v-else></span>
            </template>
            <template #[`item.publication.instagram`]="{item}">
                <span
                  v-if="item.publication.instagram"
                >
                  Oui
                </span>
              <span
                v-else-if="item.publication.instagram === 0"
              >
                  Non
                </span>
              <span v-else></span>
            </template>
            <template #[`item.author.published`]="{item}">
              <div class="text-center">
                <v-icon
                  v-if="item.published"
                  small
                  color="success"
                >
                  {{ icons.mdiCheckCircle }}
                </v-icon>
              </div>
            </template>
            <!-- pay -->
            <template #[`item.country`]="{item}">
              <div
                v-if="item.country"
                class="d-flex align-center"
              >
                <span class="text-capitalize">{{ item.country.name }}</span>
              </div>
            </template>
            <!-- status -->
            <template #[`item.huissier`]="{item}">
              <div :style="stateColor(item.communication_colors, 'huissier')">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      style="cursor: pointer"
                      v-bind="attrs"
                      @click="openDialog({...item, action: 'paiement_huissier',activity_date: item.huissier,date_column:'huissier', 'activity_date_label': $t('Huissier'), info: $t('Confirmez-vous que l\'huissier a été fait ?') })"
                      v-on="on"
                    ><span v-if="item.huissier && !item.huissier.includes('0000')">{{
                        formatDateShort(item.huissier || null, {
                          month: 'numeric',
                          day: 'numeric',
                          year: 'numeric'
                        })
                      }}</span></span>
                  </template>
                  <span
                    v-if="!item.publication_records || (item.publication_records.filter(el=>el.comment && el.type === 'paiement_huissier').length === 0)">{{
                      $t('Cliquez ici pour mettre à jour')
                    }}</span>
                  <template v-else>
                    <div>
                      <p
                        v-for="precord in item.publication_records.filter(el=>el.type === 'paiement_huissier')"

                        :key="precord.id"
                      >
                        <span class="mr-2">{{ precord.user ? precord.user.code : '' }}</span>
                        <span
                          v-if="precord.date_record"
                          class="mr-2"
                        >{{
                            formatDateShort(precord.date_record || null, {
                              month: 'numeric',
                              day: 'numeric'
                            })
                          }}</span>
                        <span class="mr-2">{{ precord.comment }}</span>
                      </p>
                    </div>
                  </template>
                </v-tooltip>

                <v-tooltip
                  v-if="!item.huissier || item.huissier.includes('0000')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      color="primary"
                      text
                      v-bind="attrs"
                      v-on="on"
                      @click="openDialog({...item, action: 'paiement_huissier', activity_date: item.huissier,date_column:'huissier', 'activity_date_label': $t('Huissier'), info: $t('Confirmez-vous que l\'huissier a été fait ?') })"
                    >
                      <v-icon color="primary">
                        {{ icons.mdiCheckboxMarkedCircleOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>
                </v-tooltip>
              </div>
            </template>


          </Row>
        </template>
      </v-data-table>
      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <rows-per-page :options="options"/>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="page"
              total-visible="6"
              :length="pageCount"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="deleteDialog"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 300 : '100%'"
    >
      <v-card>
        <v-card-title>{{ $t("Confirmer la suppression") }}</v-card-title>
        <v-divider/>
        <v-card-text>{{ $t("Confirmez-vous de vouloir supprimer") }}</v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn
            small
            outlined
            @click="deleteDialog = false"
          >
            {{ $t("Non") }}
          </v-btn>
          <v-spacer/>
          <v-btn
            small
            color="primary"
            :loading="loading"
            @click="deleteItem"
          >
            {{ $t("Oui") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      :light="$vuetify.theme.dark"
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiClose,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiPencilOutline,
  mdiCheckCircle,
  mdiCalendar,
  mdiCheckboxMarkedCircleOutline,
  mdiChartBoxOutline,
  mdiFileDocumentEditOutline,
} from '@mdi/js'
import {onUnmounted, ref, watch} from '@vue/composition-api'

// sidebar
import {avatarText, formatDate} from '@core/utils/filter'
import axios from '@axios'
import store from '@/store'
import itemStoreModule from './itemStoreModule'
import controller from './Controller'
import RowsPerPage from '@/components/RowsPerPage'

import HeaderFilters from '@/components/HeaderFilters'
import ability from '@/plugins/acl/ability'
import Row from '@/components/Row'
import ExportExcel from '@/components/ExportExcel'
import AuthorWidget from "@/views/apps/author/author-view/components/AuthorWidget.vue";

export default {
  components: {
    AuthorWidget,
    ExportExcel,
    Row,
    HeaderFilters,
    RowsPerPage,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-publication'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, itemStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      item,
      items,
      saved,
      downloadObject,
      tableCommonColumns,
      communicationTableColumns: tableColumns,
      filters,
      tabFilter,
      searchQuery,
      payFilter,
      sourceFilter,
      statusFilter,
      stateFilter,
      totalItems,
      loading,
      options,
      publicationTotalLocal,
      selectedRows,
      isFormActive,
      isFormAttributionActive,
      snackBarMessage,
      isSnackbarBottomVisible,
      openDialog,
      openDialogAttribution,
      deleteItem,
      isPublicationbod,
      deleteDialog,
      confirmDelete,
      fetchItems,
      resolvePublicationRoleVariant,
      resolvePublicationRoleIcon,
      resolvePublicationStatusVariant,
      resolvePublicationStatusName,
      resolvePublicationTotalIcon,
    } = controller()

    const superiors = ref([])
    const assistants = ref([])
    const assistantsText = ref([])

    const payOptions = [
      {title: 'Admin', value: 'admin'},
      {title: 'Publication', value: 'publication'},
      {title: 'Editor', value: 'editor'},
      {title: 'Maintainer', value: 'maintainer'},
      {title: 'Subscriber', value: 'subscriber'},
    ]

    const planOptions = [
      {title: 'Basic', value: 'basic'},
      {title: 'Company', value: 'company'},
      {title: 'Enterprise', value: 'enterprise'},
      {title: 'Standard', value: 'standard'},
    ]

    const statusOptions = [
      {title: 'Actif', value: 1},
      {title: 'Inactif', value: 0},
    ]
    axios
      .post('/particular_case/list', { page: 1000 })
      .then(response => {
        //store.state['app-manuscript/countries'] = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'publication.manuscript.particular_case.label')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data
          filters.value[indexH].fields[0].items = response.data
        }
      }).catch(error => console.log(error))

    store
      .dispatch('app-publication/fetchCountries', {per_page: 500})
    store
      .dispatch('app-publication/fetchSources', {per_page: 500})
    store
      .dispatch('app/fetchGenres', {per_page: 500})
    store
      .dispatch('app/fetchFormats', {per_page: 500})
    store
      .dispatch('app-publication/fetchStatuses', {per_page: 500, status_type: 'publication'})

    tabFilter.value = 'communication'

    // filters.value = tableCommonColumns
    filters.value.push(...tableColumns.filter(ele => !ele.value || ability.can(`view_${ele.value}`, 'Communication')))

    /* filters.value.push(
      {
        text: 'Actions',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ) */
    // stateFilter.value = [6]

    axios
      .get('/communications/text/settings', {
        params: {},
      })
      .then(response => {
        if (response.data && response.data.compteur_actions) {
          const indexH = filters.value.findIndex(ele => ele.value === 'compteur_actions')
          if (indexH > 0) {
            filters.value[indexH].filters[0].values = response.data.compteur_actions.map(ele => ({
              id: ele.compteur_actions, label: ele.compteur_actions,
            }))
          }
        }
      })

    axios
      .get('/genres', {params: {per_page: 500, type: 'publication'}})
      .then(response => {
        store.state['app/genres'] = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'publication.genre.label')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data.data
        }
      })
      .catch(error => console.log(error))

    // if (ability.can('assistant', 'Publication')) {
    axios
      .get('/listWorkers', {
        params: {
          term: '',
          type: 'communication', /* user: JSON.parse(localStorage.getItem('userData')).id || null, */
          rowsPerPage: 100,
        },
      })
      .then(response => {
        for (let i = 0; i < response.data.length; i++) {
          const u = response.data[i]
          const indexU = assistants.value.findIndex(el => el.id === u.id)
          if (indexU < 0) {
            assistants.value.push(u)
          }
        }
        const indexH = filters.value.findIndex(ele => ele.value === 'assistant.code')
        if (indexH > 0) {
          filters.value[indexH].filter.values = assistants.value
        }
      })

    axios
      .get('/listWorkers', {
        params: {
          term: '',
          type: 'text_cover',
          user: /* JSON.parse(localStorage.getItem('userData')).id || */null,
          rowsPerPage: 100,
        },
      })
      .then(response => {
        for (let i = 0; i < response.data.length; i++) {
          const u = response.data[i]
          const indexU = assistantsText.value.findIndex(el => el.id === u.id)
          if (indexU < 0) {
            assistantsText.value.push(u)
          }
        }
        const indexH = filters.value.findIndex(ele => ele.value === 'publication.assistant.code')
        if (indexH > 0) {
          filters.value[indexH].filter.values = assistantsText.value
        }
      })

    axios
      .post('/particular_case/list', { page: 1000 })
      .then(response => {
        //store.state['app-manuscript/countries'] = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'manuscript.particular_case.label')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data
          filters.value[indexH].fields[0].items = response.data
        }
      }).catch(error => console.log(error))

    // }

    const formatDateShort = (date, option) => formatDate(date, option)

    // console.log(filters.value.map(ele => ele.text + " => $communication->" + ele.value))
    const windowHeight = ref(window.innerHeight)
    const tableHeight = ref(window.innerHeight - 250)
    window.addEventListener('resize', () => {
      windowHeight.value = window.innerHeight
    })
    watch(windowHeight, () => {
      tableHeight.value = windowHeight.value - 250
    })

    return {
      tableHeight,
      item,
      items,
      saved,
      downloadObject,
      superiors,
      tableColumns,
      searchQuery,
      tabFilter,
      filters,
      payFilter,
      sourceFilter,
      statusFilter,
      stateFilter,
      totalItems,
      payOptions,
      planOptions,
      statusOptions,
      loading,
      options,
      assistants,
      publicationTotalLocal,
      isFormActive,
      selectedRows,
      snackBarMessage,
      isFormAttributionActive,
      isSnackbarBottomVisible,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      openDialog,
      openDialogAttribution,
      deleteItem,
      deleteDialog,
      confirmDelete,
      avatarText,
      formatDate,
      formatDateShort,
      isPublicationbod,
      resolvePublicationRoleVariant,
      resolvePublicationRoleIcon,
      resolvePublicationStatusVariant,
      resolvePublicationStatusName,
      resolvePublicationTotalIcon,
      fetchItems,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiClose,
        mdiExportVariant,
        mdiAccountOutline,
        mdiPencilOutline,
        mdiCheckCircle,
        mdiCalendar,
        mdiCheckboxMarkedCircleOutline,
        mdiChartBoxOutline,
        mdiFileDocumentEditOutline,
      },
    }
  },
}
</script>
<style lang="scss">
/*table {

  background-color: white;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
  display: block;
  overflow-x: scroll;
}
.v-data-table td, th {
  margin: 0;
  border: 1px solid grey;
  white-space: nowrap;
  border-top-width: 0px;
}

.v-data-table thead tr:first-child
}
.fixed-column {
  position: absolute;
  width: 5em;
  left: 0;
  top: auto;
  border-top-width: 1px;
  margin-top: -1px;
}*/
</style>
<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
